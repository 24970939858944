import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { isNil } from 'lodash';
import {
  InputHelperText,
  Button as RaButton,
  SelectInput,
  required,
  useInput,
  useTranslate
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Check, ContentCopy } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  darken,
  lighten,
  styled
} from '@mui/material';
import {
  CRYPTO_KEY_TYPE_IDS,
  DEFAULT_CRYPTO_KEY_ENCODING_KEY
} from '@rc/admin/constants';
import { useDecodedRecordField } from '@rc/admin/hooks';
import {
  makeOptionallyNestedSource,
  useCopyToClipboard,
  validateGitRepository
} from './utils';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {boolean} props.isHttpCredentialType
 * @param {function} props.handleFieldChanged
 * @param {function} props.createOrUpdateGit
 * @param {boolean} props.isCreateOrUpdateGitLoading
 * @returns
 */
export const PrivateKeyInputs = props => {
  const {
    source,
    isHttpCredentialType,
    handleFieldChanged,
    createOrUpdateGit,
    isCreateOrUpdateGitLoading
  } = props;

  const t = useTranslate();
  const { setValue, getValues } = useFormContext();

  const git = useWatch(source ? { name: source } : undefined);

  const publicKeySource = makeOptionallyNestedSource(source, 'publicKey');
  const {
    id: publicKeyId,
    field: publicKeyField,
    fieldState: { error, isTouched, invalid },
    formState: { isSubmitted }
  } = useInput({
    source: publicKeySource,
    validate: [required()]
  });

  // Set default values
  useEffect(() => {
    const values = source ? getValues(source) : getValues();

    if (!values.cryptoKeyType) {
      setValue(
        makeOptionallyNestedSource(source, 'cryptoKeyType'),
        CRYPTO_KEY_TYPE_IDS[DEFAULT_CRYPTO_KEY_ENCODING_KEY],
        {
          shouldValidate: false,
          shouldDirty: true,
          shouldTouch: true
        }
      );
    }
  }, [getValues, setValue, source]);

  const publicKeyDecoded = useDecodedRecordField({
    record: git,
    source: 'publicKey',
    decodeBase64: true
  });

  const {
    isCopiedToClipboard,
    copyToClipboard,
    reset: resetCopyToClipboard
  } = useCopyToClipboard(publicKeyDecoded);

  const handleCryptoKeyTypeChanged = useCallback(
    e => handleFieldChanged('cryptoKeyType', e.target.value),
    [handleFieldChanged]
  );

  return (
    <StyledGrid container spacing={1}>
      <Grid item sm={9}>
        <Box spacing={2}>
          <input type='hidden' id={publicKeyId} {...publicKeyField} />
          <TextField
            disabled
            size='small'
            fullWidth
            className={clsx(
              classes.publicKeyInput,
              publicKeyField.value && classes.publicKeyInput_hasValue
            )}
            label={t('components.git.fields.publicKey')}
            value={publicKeyDecoded}
            error={(isTouched || isSubmitted) && invalid}
            helperText={
              error?.message ? <InputHelperText
                touched={isTouched || isSubmitted}
                error={error?.message}
              /> : false
            }
            onMouseLeave={
              publicKeyField.value ? resetCopyToClipboard : undefined
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {!publicKeyField.value ? (
                    <RaButton
                      type='button'
                      size='small'
                      variant='contained'
                      label='components.git.action.generate_key'
                      onClick={createOrUpdateGit}
                      disabled={
                        isCreateOrUpdateGitLoading ||
                        isNil(git?.cryptoKeyType) ||
                        !!publicKeyField.value ||
                        !git?.repo ||
                        (git.repo &&
                          !!validateGitRepository(isHttpCredentialType)(
                            git.repo
                          ))
                      }
                      endIcon={
                        isCreateOrUpdateGitLoading && (
                          <CircularProgress size={18} />
                        )
                      }
                    />
                  ) : (
                    <Tooltip
                      title={t(
                        isCopiedToClipboard
                          ? 'misc.copied_to_clipboard'
                          : 'misc.copy_to_clipboard'
                      )}
                      placement='bottom'
                    >
                      <IconButton
                        onClick={
                          publicKeyField.value ? copyToClipboard : undefined
                        }
                        color='default'
                        aria-label={t('misc.copy_to_clipboard')}
                      >
                        {isCopiedToClipboard ? (
                          <Check fontSize='small' />
                        ) : (
                          <ContentCopy fontSize='small' />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Grid>
      <Grid item sm={3}>
        <SelectInput
          className={classes.encodingSelect}
          source={makeOptionallyNestedSource(source, 'cryptoKeyType')}
          label='components.git.fields.encoding'
          choices={Object.entries(CRYPTO_KEY_TYPE_IDS).map(([key, value]) => ({
            id: value,
            name: key
          }))}
          validate={[required()]}
          fullWidth
          onChange={handleCryptoKeyTypeChanged}
          helperText={false}
        />
      </Grid>
    </StyledGrid>
  );
};

const PREFIX = 'PrivateKeyInputs';

const classes = {
  publicKeyInput: `${PREFIX}-publicKeyInput`,
  publicKeyInput_hasValue: `${PREFIX}-publicKeyInput_hasValue`,
  encodingSelect: `${PREFIX}-encodingSelect`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.encodingSelect}`]: {
    minWidth: 'unset'
  },

  [`& .${classes.publicKeyInput}.${classes.publicKeyInput_hasValue}`]: {
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: 8
    },
    '&:hover': {
      '& .MuiInputBase-input, & .MuiInputAdornment-root button': {
        WebkitTextFillColor: (theme.palette.mode === 'dark' ? lighten : darken)(
          theme.palette.text.disabled,
          0.5
        )
      }
    }
  }
}));
