import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Grid, Stack, styled, Typography } from '@mui/material';
import {
  EXTERNAL_GIT_TYPE_ID,
  HTTP_GIT_CREDENTIAL_TYPE_ID,
  SSH_GIT_CREDENTIAL_ID
} from '@rc/admin/constants';
import {
  CheckboxGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useNotify,
  useTranslate
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { cryptoKeyConnectionTestedSource } from '@rc/admin/resources/project/Create/utils';
import { useAxios } from '@rc/utils/axios';
import { axiosDataToRaRecord } from '@rc/admin/utils';
import { requiredIf } from '@rc/admin/utils/form';
import { makeOptionallyNestedSource, validateGitRepository } from './utils';
import { ConnectionTest } from './ConnectionTest';
import { PrivateKeyInputs } from './PrivateKeyInputs';

export const CredentialInputs = props => {
  const { source, ...rest } = props;
  const t = useTranslate();
  const { clearErrors, setValue, getValues } = useFormContext();
  const { createOrUpdateGit, isLoading: isCreateOrUpdateGitLoading } =
    useCreateOrUpdateGit({ source });

  const [gitTypeValue, credentialTypeValue, gitOriginId, publicKey, repo] =
    useWatch({
      name: [
        makeOptionallyNestedSource(source, 'gitType'),
        makeOptionallyNestedSource(source, 'credentialType'),
        makeOptionallyNestedSource(source, 'originId'),
        makeOptionallyNestedSource(source, 'publicKey'),
        makeOptionallyNestedSource(source, 'repo')
      ]
    });

  const isExternalGit = gitTypeValue === EXTERNAL_GIT_TYPE_ID;

  const isHttpCredentialType =
    credentialTypeValue === HTTP_GIT_CREDENTIAL_TYPE_ID;

  const resetConnectionTestValidation = useCallback(() => {
    setValue(cryptoKeyConnectionTestedSource, false, {
      shouldValidate: false,
      shouldDirty: true,
      shouldTouch: true
    });
  }, [setValue]);

  const handleClearPublicKey = useCallback(() => {
    setValue(makeOptionallyNestedSource(source, 'publicKey'), null);
    resetConnectionTestValidation();
    clearErrors(cryptoKeyConnectionTestedSource);
  }, [clearErrors, resetConnectionTestValidation, setValue, source]);

  const handleFieldChanged = useCallback(
    (field, value) => {
      const currentValue = getValues(makeOptionallyNestedSource(source, field));
      if (currentValue !== value) {
        handleClearPublicKey();
      }
    },
    [getValues, handleClearPublicKey, source]
  );

  const handleGitUrlChange = useCallback(
    e => handleFieldChanged('repo', e.target.value),
    [handleFieldChanged]
  );

  const handleUsernameChanged = useCallback(
    e => handleFieldChanged('username', e.target.value),
    [handleFieldChanged]
  );

  const handlePasswordChanged = useCallback(
    e => handleFieldChanged('password', e.target.value),
    [handleFieldChanged]
  );

  const handlePasteGitUrl = useCallback(
    event => {
      const clipboardText = event.clipboardData.getData('text').trim();
      const gitCloneRegex = /^git clone /;
      if (gitCloneRegex.test(clipboardText)) {
        event.preventDefault();
        setValue(
          makeOptionallyNestedSource(source, 'repo'),
          clipboardText.replace(gitCloneRegex, '')
        );
      }
    },
    [setValue, source]
  );

  useEffect(() => {
    if (gitTypeValue === EXTERNAL_GIT_TYPE_ID && !credentialTypeValue) {
      setValue(
        makeOptionallyNestedSource(source, 'credentialType'),
        HTTP_GIT_CREDENTIAL_TYPE_ID,
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true
        }
      );
    }
  }, [credentialTypeValue, gitTypeValue, setValue, source]);

  if (!isExternalGit) {
    return null;
  }

  return (
    <StyledStack>
      <Grid
        container
        direction={{ xs: 'column', sm: 'row-reverse' }}
        wrap='wrap'
        spacing={1}
      >
        <Grid item sm={3}>
          <ReferenceInput
            source={makeOptionallyNestedSource(source, 'credentialType')}
            reference='credential_types'
          >
            <SelectInput
              label={'components.git.fields.credentialType'}
              className={classes.credentialTypeSelect}
              optionText={option => {
                const result = option.name.toUpperCase();
                if (result === 'HTTP') {
                  return 'HTTPS';
                }
                return result;
              }}
              validate={[required()]}
              fullWidth
            />
          </ReferenceInput>
        </Grid>

        <Grid item sm={9}>
          <TextInput
            {...rest}
            source={makeOptionallyNestedSource(source, 'repo')}
            label={'components.git.fields.url'}
            placeholder={
              credentialTypeValue
                ? isHttpCredentialType
                  ? 'https://github.com/example.git'
                  : 'git@github.com:example.git'
                : ''
            }
            validate={[required(), validateGitRepository(isHttpCredentialType)]}
            style={{ maxWidth: '500px' }}
            fullWidth
            onChange={handleGitUrlChange}
            onPaste={handlePasteGitUrl}
          />
        </Grid>
      </Grid>
      {credentialTypeValue ? (
        isHttpCredentialType ? (
          <Grid container wrap='wrap' spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextInput
                {...rest}
                id={'git-username'}
                className={classes.block}
                source={makeOptionallyNestedSource(source, 'username')}
                label={'components.git.fields.username'}
                onChange={handleUsernameChanged}
                autoComplete='off'
                validate={[
                  requiredIf(
                    makeOptionallyNestedSource(source, 'password_encoded')
                  )
                ]}
                fullWidth
                helperText={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                {...rest}
                id={'git-password'}
                type={'password'}
                className={classes.block}
                source={makeOptionallyNestedSource(source, 'password_encoded')}
                label={'components.git.fields.password'}
                onChange={handlePasswordChanged}
                autoComplete='off'
                // format={value => (value == null ? '' : decodeBase64(value))}
                validate={[
                  requiredIf(makeOptionallyNestedSource(source, 'username'))
                ]}
                fullWidth
                helperText={false}
              />
            </Grid>
          </Grid>
        ) : (
          <PrivateKeyInputs
            source={source}
            isHttpCredentialType={isHttpCredentialType}
            onClearPublicKey={handleClearPublicKey}
            handleFieldChanged={handleFieldChanged}
            createOrUpdateGit={createOrUpdateGit}
            isCreateOrUpdateGitLoading={isCreateOrUpdateGitLoading}
          />
        )
      ) : null}

      <Grid item xs={12} py={2}>
        <Typography variant='caption' sx={{ width: '100%' }}>
          {t('components.git.message.public_repo_credentials_message')}
        </Typography>
      </Grid>

      {isHttpCredentialType && !!repo?.match('github.com') && (
        <Alert
          severity='warning'
          sx={theme => ({ marginBottom: theme.spacing(2) })}
        >
          {t('components.git.message.github_password_unsupported')}
        </Alert>
      )}

      <ConnectionTest
        source={cryptoKeyConnectionTestedSource}
        gitOriginId={gitOriginId}
        disabled={!repo || (!isHttpCredentialType && !publicKey)}
        createOrUpdateGit={createOrUpdateGit}
        isCreateOrUpdateGitLoading={isCreateOrUpdateGitLoading}
      />

      <CheckboxGroupInput
        source='__confirm_write_access'
        label=''
        optionText={'label'}
        choices={[
          {
            id: 'verified',
            label: 'components.git.fields.__confirm_write_access'
          }
        ]}
        validate={value =>
          !Array.isArray(value) || !value?.includes('verified')
            ? required()()
            : undefined
        }
      />
    </StyledStack>
  );
};

const useCreateOrUpdateGit = props => {
  const { source } = props;

  const { setValue, getValues, reset } = useFormContext();
  const [, executeCreate] = useAxios('api/gits', { manual: true });
  const [, executeUpdate] = useAxios('api/gits', { manual: true });
  const previousGitRef = useRef(null);
  const notify = useNotify();
  const [isLoading, setLoading] = useState(false);

  const createOrUpdateGit = useCallback(async () => {
    const values = source ? getValues()[source] : getValues();
    const submitData = { ...values };

    const previousGit =
      previousGitRef.current || (values.originId ? values : null);

    try {
      setLoading(true);

      const headers = getShouldRegeneratePublicKey(previousGit, values)
        ? { 'x-change-cryptokey': 'true' }
        : {};

      const { data: result } = await (!values.originId
        ? executeCreate({
            method: 'POST',
            url: 'api/gits',
            data: sanitizeSubmitValues(submitData),
            headers
          })
        : executeUpdate({
            method: 'PUT',
            url: `api/gits/${values.originId}`,
            data: sanitizeSubmitValues(submitData),
            headers
          }));

      const record = axiosDataToRaRecord(result, data => ({
        ...data,
        password_encoded: data.password
      }));
      previousGitRef.current = record;

      if (source) {
        setValue(source, record, {
          shouldDirty: false
        });
      } else {
        reset(record, {
          keepDirtyValues: false
        });
      }

      setLoading(false);
      return { data: record };
    } catch (error) {
      setLoading(false);
      notify(error?.message ?? 'components.git.message.key_generation_failed', {
        type: 'error'
      });
      return { error };
    }
  }, [
    executeCreate,
    executeUpdate,
    getValues,
    notify,
    reset,
    setValue,
    source
  ]);

  return {
    createOrUpdateGit,
    isLoading
  };
};

const getShouldRegeneratePublicKey = (prevGit, currGit) => {
  if (currGit.credentialType !== SSH_GIT_CREDENTIAL_ID) {
    return false;
  }

  return (
    !prevGit ||
    prevGit.repo !== currGit.repo ||
    prevGit.cryptoKeyType !== currGit.cryptoKeyType
  );
};

const sanitizeSubmitValues = values => {
  if (values.credentialType === HTTP_GIT_CREDENTIAL_TYPE_ID) {
    delete values.cryptoKeyType;
    delete values.publicKey;
    values.password = values.password_encoded;
  } else {
    delete values.username;
    delete values.password;
    if (values.publicKey !== null) {
      delete values.publicKey;
    }
  }

  delete values.password_encoded;

  return values;
};

const PREFIX = 'GitCredentials';

const classes = {
  block: `${PREFIX}-block`,
  credentialTypeSelect: `${PREFIX}-credentialTypeSelect`
};

const StyledStack = styled(Stack)(() => ({
  [`& .${classes.block}`]: {
    display: 'block',
    width: '100%'
  },

  [`& .${classes.credentialTypeSelect}`]: {
    minWidth: 'unset'
  }
}));
